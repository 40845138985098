import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import Button from '../helpers/button/Button';

import './TopBar.scss';

const TopBar = () => {
  const location = useLocation();

  const getClassName = (path: string): string => {
    console.log(location.pathname);
    console.log(path);
    if (location.pathname === path) {
      return 'button__label--active';
    }

    return 'button__label--inactive';
  };

  return (
    <div className="top-bar">
      <h1 className="title">Appvertising Ltd</h1>
      <div className="navigation">
        <Link to="/">
          <Button>
            <span className={getClassName('/')}>HOME</span>
          </Button>
        </Link>
        <Link to="/about">
          <Button>
            <span className={getClassName('/about')}>ABOUT</span>
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default TopBar;
