export type AppInfo = {
  imageUrl: string;
  name: string;
  description: string;
  playStoreUrl: string;
  availableOnPlayStore: boolean;
  availableOnAppStore: boolean;
  comingSoon: boolean;
};

const WORDS_AGAINST_HUMANITY: AppInfo = {
  availableOnAppStore: false,
  availableOnPlayStore: true,
  comingSoon: false,
  description:
    "A card game in which the players are given the same question or statement card that they have to answer or fill in to make the funniest combination against humanity. Words Against Humanity allows you to play fun card game free online in which players use their cards against other players' cards to beat them making the funniest combination. Then one player compares combinations of the cards against each other and picks the funniest one. The first one who collects 5, 10 or 15 points is a winner! ",
  imageUrl: 'res/wah.png',
  name: 'Words Against Humanity',
  playStoreUrl: 'https://play.google.com/store/apps/details?id=com.laughagainsthumanity'
};

const CRYPTO_SIMULATOR: AppInfo = {
  availableOnAppStore: false,
  availableOnPlayStore: true,
  comingSoon: false,
  description:
    'Cryptocurrency exchange simulator. Realistic cryptocurrency tracker for 1200+ cryptocurrency including Bitcoin, Ethereum, Litecoin, Bitcoin, Bitcoin cash, Zcash, Dash, Ripple, Monero and other cryptocurrency. Easy way to learn investing crypto and test your skills as a crypto broker. You can also use the cryptocurrency tracker to see latest data for last hour, day week or month of your desired cryptocurrency.',
  imageUrl: 'res/crypto.png',
  name: 'Cryptocurrency Exchange Simulator',
  playStoreUrl:
    'https://play.google.com/store/apps/details?id=com.cryptobrokergame'
};

// const PROFITABULL: AppInfo = {
//   availableOnAppStore: false,
//   availableOnPlayStore: false,
//   comingSoon: true,
//   description: '',
//   imageUrl: '',
//   name: 'ProfitaBull',
//   playStoreUrl: ''
// };

export const CURRENT_APPS: AppInfo[] = [
  WORDS_AGAINST_HUMANITY,
  CRYPTO_SIMULATOR
];
