import React from 'react';

import './About.scss';

const About = () => {
  return (
    <div className="about">
      <div className="content">
        <span>
          Appvertising Limited is a Software Development company, specialized in
          building Mobile App products.
        </span>
        <span>We build Apps for Android and iOS devices.</span>
        <span>
          Check out the
          <a href="/"> apps </a>
          we own.
        </span>
      </div>
      <div className="info">
        <div className="email">
          <span className="email__text">
            <img
              className="email__icon"
              src="res/email.svg"
              height={30}
              width={30}
              alt="email-icon"
            />
            tihomir.bozic@appvertising.co.uk
          </span>
        </div>
        <div className="address">
          <span>Address:</span>
          <span className="street_address_1">Suite 2a Blackthorn House</span>
          <span className="street_address_2">St. Pauls Square</span>
          <span className="company_city">Birmingham</span>
          <span className="company_postal">B3 1RL </span>
          <span className="company_country">United Kingdom</span>
        </div>
      </div>
    </div>
  );
};

export default About;
