import React from 'react';

import { AppInfo } from '../../../model/AppInfo';

import './AppInfoPreview.scss';

interface AppInfoPreviewProps {
  app: AppInfo;
}

const AppInfoPreview = ({ app }: AppInfoPreviewProps) => {
  const getBadge = (): JSX.Element => {
    if (app.comingSoon) {
      return <div className="app-info-preview__coming-soon">Coming soon!</div>;
    }

    return (
      <a href={app.playStoreUrl}>
        <img
          className="app-info-preview__badge"
          alt="Get it on Google Play"
          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
        />
      </a>
    );
  };

  return (
    <div className="app-info-preview">
      <span className="app-info-preview__title">{app.name}</span>
      <img
        className="app-info-preview__image"
        src={app.imageUrl}
        alt="preview"
      />
      {getBadge()}
      <span className="app-info-preview__description">{app.description}</span>
    </div>
  );
};

export default AppInfoPreview;
