import React from 'react';
import { Switch, Route } from 'react-router-dom';

import About from '../about/About';
import Home from '../home/Home';

import './AppBody.scss';

const AppBody = () => (
  <div className="app-body">
    <Switch>
      <Route path="/about">
        <About />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  </div>
);

export default AppBody;
