import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import TopBar from './components/top-bar/TopBar';
import AppBody from './components/app-body/AppBody';

import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
    <div className="App">
      <Router>
        <header className="App-header">
          <TopBar />
        </header>
        <body>
          <AppBody />
        </body>
      </Router>
    </div>
  );
};

export default App;
