import React from 'react';

import { CURRENT_APPS } from '../../model/AppInfo';
import AppInfoPreview from '../helpers/app-info-preview/AppInfoPreview';

import './Home.scss';

const Home = () => {
  const getApps = (): JSX.Element[] => {
    return CURRENT_APPS.map((value) => {
      return <AppInfoPreview app={value} />;
    });
  };

  return (
    <div className="home">
      <div className="content">{getApps()}</div>
    </div>
  );
};

export default Home;
