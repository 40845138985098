import React, { ReactNode } from 'react';

import './Button.scss';

const Button = ({ children }: { children: ReactNode }) => (
  <button type="button" className="btn btn-lg shadow-none">
    {children}
  </button>
);

export default Button;
